@import url("https://fonts.googleapis.com/css2?family=Fira+Code&display=swap");

.control .icon {
  pointer-events: all;
}



$family-sans-serif: "Roboto", sans-serif;
$info: #b9e1dc;
$link: #7080da;
// $success: #49d67f;
$danger: #d45f46;
$body-background-color: #fbfbfb;
$footer-padding: 1.5rem 1.5rem 1.5rem;

@import "../node_modules/bulma/bulma.sass";

html,
body {
  min-height: 100vh;
}

.pointer {
  cursor: pointer;
}

// .react-datepicker-wrapper{
//   justify-content: center

// }
.react-datepicker__input-container {
  text-align: center;
}

.fa-small {
  font-size: 6px;
}

#root {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

#wrapper {
  flex: 1;
}


.is-down {
  margin-top: auto;
}


.hdg-label-popup {
  display: none;
  position: absolute
}

.icon:hover~.hdg-label-popup {
  display: block
}

.table-container {
  height: 400px;
  overflow: scroll;
}

.upload-tasks-box {
  max-height: 400px;
  /* Set the max height for the main box */
  overflow-y: scroll;
  /* Make the main box scrollable */
}